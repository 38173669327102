module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-emotion-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"symbol":"🌙","canvas":"#f9f9f9","ink":"#222831","paleInk":"#393e46","homeLogoBackground":"#222831","homeLogoText":"#fecb00","link":"#30475e","borderDefault":"#d2c9b1","tableRowBackground":"#d2c9b1","canvasLine":"#d2c9b1","shadow":"#393e46","navTitle":"#fecb00"},"dark":{"symbol":"🌞","canvas":"#222831","ink":"#f9f9f9","paleInk":"#d2c9b1","homeLogoBackground":"#fecb00","homeLogoText":"#222831","link":"#f9f9f9","borderDefault":"#393e46","tableRowBackground":"#393e46","canvasLine":"#393e46","shadow":"#d2c9b1","navTitle":"#fecb00"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
